import React, { useState, useRef, useEffect } from 'react';
import Image from 'gatsby-image';
import StackGrid from 'react-stack-grid';
import PropTypes from 'prop-types';
import s from '../../Feature.module.scss';

const propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hideTitle: PropTypes.bool,
    description: PropTypes.shape({ description: PropTypes.string.isRequired }),
    imageSliders: PropTypes.arrayOf(PropTypes.shape({ fluid: PropTypes.shape({}) })).isRequired,
    backgroundColor: PropTypes.string,
    viewType: PropTypes.string,
  }).isRequired,
  size: PropTypes.shape({ width: PropTypes.number }),
};

const defaultProps = {
  size: {
    width: 1024,
  },
};

const ViewType4 = ({ item, size }) => {
  const [clickStartX, setClickStartX] = useState();
  const [scrollStartX, setScrollStartX] = useState();
  const scrollWrapperRef = useRef();

  useEffect(() => {
    if (scrollWrapperRef.current) {
      const handleDragStart = (e) => {
        e.preventDefault();
        setClickStartX(e.screenX);
        setScrollStartX(scrollWrapperRef.current.scrollLeft);
      };

      const handleDragMove = (e) => {
        e.preventDefault();
        if (clickStartX !== undefined && scrollStartX !== undefined) {
          const touchDelta = clickStartX - e.screenX;
          scrollWrapperRef.current.scrollLeft = scrollStartX + touchDelta;
        }
      };

      const handleDragEnd = () => {
        if (clickStartX !== undefined) {
          setClickStartX(undefined);
          setScrollStartX(undefined);
        }
      };

      if (scrollWrapperRef.current.ontouchstart === undefined) {
        scrollWrapperRef.current.onmousedown = handleDragStart;
        scrollWrapperRef.current.onmousemove = handleDragMove;
        scrollWrapperRef.current.onmouseup = handleDragEnd;
        scrollWrapperRef.current.onmouseleave = handleDragEnd;
      }
    }
  });

  return (
    <div className={s.root}>
      <div
        className={[s.viewType4, s.container].join(' ')}
        style={{ background: item.backgroundColor }}
      >
        <div className={s.subContainer}>
          <section className={s.textBlock}>
            {!item.hideTitle && <h2>{item.title}</h2>}
            <p className={s.description}>{item.description.description}</p>
          </section>
        </div>
      </div>
      <div className={s.scrollContainer} ref={scrollWrapperRef}>
        <div className={s.scrollImageSection}>
          <StackGrid columnWidth={size.width <= 768 ? '100%' : '30%'} style={{ width: '100%' }}>
            {item.imageSliders.map((img) => (
              <Image key={img.id} fluid={img.fluid} />
            ))}
          </StackGrid>
        </div>
      </div>
    </div>
  );
};

ViewType4.propTypes = propTypes;
ViewType4.defaultProps = defaultProps;
export default ViewType4;
