import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import s from '../../Feature.module.scss';

const propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hideTitle: PropTypes.bool,
    description: PropTypes.shape({ description: PropTypes.string.isRequired }),
    imageSliders: PropTypes.arrayOf(PropTypes.shape({ fluid: PropTypes.shape({}) })).isRequired,
    backgroundColor: PropTypes.string,
    viewType: PropTypes.string,
  }).isRequired,
};

const ViewType3 = ({ item }) => (
  <div className={s.root}>
    <div
      className={[s.viewType3, s.container].join(' ')}
      style={{ background: item.backgroundColor }}
    >
      <div className={s.subContainer}>
        <section className={s.textBlock}>
          {!item.hideTitle && <h2>{item.title}</h2>}
          <p className={s.description}>{item.description.description}</p>
        </section>
      </div>
      <div className={s.imageSection}>
        {item.imageSliders.map((img) => (
          <div className={s.imageItem} key={`${img.id}${Math.random()}`}>
            <Image fluid={img.fluid} />
          </div>
        ))}
      </div>
    </div>
  </div>
);

ViewType3.propTypes = propTypes;
export default ViewType3;
