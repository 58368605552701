import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import MarkdownIt from 'markdown-it';
import Layout from '../../components/Layout';
import fluidImagePropTypes from '../../constants/propTypes';

import { getSeoConfig } from '../../constants/defaultSeoProps';
import Partner from '../../components/Partner';
import { partnersData } from '../Contact/partnersData';
import CaseStudyReferences from '../Work/Project';
import CasestudyHeader from './CasestudyHeader';
import Feature from './Feature';
import WorkScope from './WorkScope';
import s from './Casestudy.module.scss';

const parse = require('html-react-parser');

const md = new MarkdownIt({
  html: true,
  linkify: false,
});

const propTypes = {
  detail: PropTypes.shape({
    bannerTitle: PropTypes.string.isRequired,
    headerBgColor: PropTypes.string,
    bannerDescription: PropTypes.shape({ bannerDescription: PropTypes.string }),
    bannerButtonTitle: PropTypes.string,
    caseStudyUri: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    featuredImage: PropTypes.shape({ fluid: PropTypes.shape(fluidImagePropTypes) }),
    fullImage: PropTypes.shape({ fluid: PropTypes.shape({}) }),
    fullImageCaption: PropTypes.shape({ fullImageCaption: PropTypes.string }),
    introTitle1: PropTypes.string,
    introDescription1: PropTypes.shape({ introDescription1: PropTypes.string }),
    introTitle2: PropTypes.string,
    introDescription2: PropTypes.shape({ introDescription2: PropTypes.string }),
    caseStudyContent: PropTypes.shape({ caseStudyContent: PropTypes.string }),
    introTitle3: PropTypes.string,
    introDescription3: PropTypes.shape({ introDescription3: PropTypes.string }),
    caseStudyFeatures: PropTypes.array,
    workScope: PropTypes.array,
    referenceLink: PropTypes.string,
    listTitle: PropTypes.string,
    fullImage2: PropTypes.shape({ fluid: PropTypes.shape({}) }),
    caseStudyReferences: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.shape({ description: PropTypes.string.isRequired }),
        technologies: PropTypes.array.isRequired,
        bgColor: PropTypes.string.isRequired,
        brandLogo: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
        productImage: PropTypes.shape({ fluid: PropTypes.shape({}) }).isRequired,
        moreInfoTitle: PropTypes.string,
        caseStudyPage: PropTypes.shape({ caseStudyUri: PropTypes.string }),
      }),
    ),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.array.isRequired,
  }).isRequired,
};

const Casestudy = ({ detail, location, companyQuote }) => {
  const headerProps = {
    headerBgColor: detail.headerBgColor,
    bannerDescription: detail.bannerDescription,
    bannerTitle: detail.bannerTitle,
    bannerButtonTitle: detail.bannerButtonTitle,
    tags: detail.tags,
    featuredImage: detail?.featuredImage,
    referenceLink: detail.referenceLink,
  };
  let data;
  if (detail.caseStudyContent && detail.caseStudyContent.caseStudyContent) {
    data = JSON.parse(detail.caseStudyContent.caseStudyContent);
  }

  const challangeHtml =
    detail.introDescription1?.introDescription1 &&
    md.render(detail.introDescription1.introDescription1);
  const solutionHtml =
    detail.introDescription2?.introDescription2 &&
    md.render(detail.introDescription2.introDescription2);
  const benifitHtml =
    detail.introDescription3?.introDescription3 &&
    md.render(detail?.introDescription3?.introDescription3);

  const seoParams = {
    ogTitle: `Tech Holding | ${detail?.bannerTitle}`,
    ogDesc: detail?.introDescription1?.introDescription1
      ? detail.introDescription1.introDescription1
      : '',
    ogPath: location?.pathname ? location.pathname : '',
  };

  const updatedSeo = getSeoConfig(seoParams);
  updatedSeo.openGraph.image = detail?.featuredImage?.fluid?.src
    ? `https:${detail?.featuredImage.fluid.src}`
    : '';
  return (
    <Layout
      currentPage="/casestudy/{name}"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote?.footerDescription}
    >
      <CasestudyHeader {...headerProps} />
      <div className={s.container}>
        <section className={s.introWrapper} data-aos="fade-up" data-aos-delay="400">
          <h2>{detail.introTitle1}</h2>
          <div className={s.content}>{parse(challangeHtml)}</div>
        </section>
        <section className={s.introWrapper} data-aos="fade-up" data-aos-delay="400">
          <h2>{detail.introTitle2}</h2>
          <div className={s.content}>{parse(solutionHtml)}</div>
        </section>
        {detail.introDescription3 && detail.introDescription3.introDescription3 && (
          <section className={s.introWrapper} data-aos="fade-up" data-aos-delay="400">
            <h2>{detail.introTitle3}</h2>
            <div className={s.content}>{parse(benifitHtml)}</div>
          </section>
        )}
      </div>
      <div data-aos="fade-up" data-aos-delay="400">
        {detail.fullImage && detail.fullImage.fluid && (
          <Image className={s.fullImageWrapper} fluid={detail.fullImage.fluid} />
        )}
        {detail?.fullImageCaption?.fullImageCaption && (
          <p className={s.fullImageCaptionWrapper}>{detail.fullImageCaption.fullImageCaption}</p>
        )}
        {detail.caseStudyFeatures && <Feature features={detail.caseStudyFeatures} />}
        {detail.fullImage2 && detail.fullImage2.fluid && (
          <Image className={s.fullImageWrapper} fluid={detail.fullImage2.fluid} />
        )}
      </div>
      {data && (
        <div className={s.container} data-aos="fade-up" data-aos-delay="400">
          <div className={s.caseStudy}>
            <div className={s.large}>{documentToReactComponents(data)}</div>
          </div>
        </div>
      )}
      {detail.workScope && <WorkScope scope={detail.workScope} title={detail.listTitle} />}

      {detail.caseStudyReferences && (
        <div className={s.caseStudyContainer} data-aos="fade-up" data-aos-delay="400">
          <div className={s.caseStudyList}>
            <h2>Other Case Studies</h2>
            <div className={s.projectsContainer}>
              {detail.caseStudyReferences.map((project) => (
                <CaseStudyReferences key={project.name} project={project} />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className={s.partners}>
        <Partner partnersImages={partnersData} />
      </div>
    </Layout>
  );
};

Casestudy.propTypes = propTypes;
export default Casestudy;
