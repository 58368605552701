import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import s from '../../Feature.module.scss';

const propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    hideTitle: PropTypes.bool,
    description: PropTypes.shape({ description: PropTypes.string.isRequired }),
    imageSliders: PropTypes.arrayOf(PropTypes.shape({ fluid: PropTypes.shape({}) })).isRequired,
    backgroundColor: PropTypes.string,
    viewType: PropTypes.string,
  }).isRequired,
};

const ViewType1 = ({ item }) => (
  <div className={s.root}>
    <div
      className={[s.viewType1, s.container].join(' ')}
      style={{ background: item.backgroundColor }}
    >
      <div className={[s.subContainer, s.subContainerRow].join(' ')}>
        <section className={s.textBlockSticky}>
          {!item.hideTitle && <h2 className={s.title}>{item.title}</h2>}
          <p className={s.description}>{item.description.description}</p>
        </section>
        <section className={s.imageSection}>
          {item.imageSliders.map((img) => (
            <Image
              key={img.id}
              fluid={img.fluid}
              style={{ marginBottom: '7%', boxShadow: '0px 15px 30px -21px #888888' }}
            />
          ))}
        </section>
      </div>
    </div>
  </div>
);

ViewType1.propTypes = propTypes;
export default ViewType1;
